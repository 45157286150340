.inline-editor {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 20px;
    grid-gap: 10px;

    span, input {
        &:first-of-type {

            overflow: hidden;

        }
    }

    &__form {
        &--sizes {
            grid-gap: 2px;
            grid-template-columns: 40px 8px 40px 8px 40px 20px;
        }
    }


}

.ReactTable .rt-table .rt-tr .rt-td {
    padding-right: 25px !important;
    select {
        width: 100%;
    }
    * {
        white-space: initial !important;
    }
}
