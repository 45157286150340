.page-content {
    &__info-box-grid {
        max-width: 700px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        ul {
            list-style: none;
            margin-bottom: 50px;
        }

        &__box {

        }
    }

    &__single-product-columns {
        display: grid;
        grid-template-columns: 500px 1fr;
        grid-gap: 50px;
    }

    &__order-grid {
        @include breakpoint($md) {
            display: grid;
            grid-template-columns: 2fr 1fr;
        }

    }
}
