$xxs: 480px;
$xs: 768px;
$md: 992px;
$md-lg: 1260px;
$lg: 1440px;
$xlg: 1600px;
$header-height: 70px;

$col-1 :  #ee7203; // -- Orange
$col-2 : #878787;
:root {
    --col-2 : #53CD69; // -- Green
}
