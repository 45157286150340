// -- Import termina
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$roboto : 'Roboto', sans-serif;

// -- Font weights
$thin : 100;
$extra-light : 200;
$light : 300;
$regular : 400;
$medium : 500;
$semiBold : 600;
$bold : 700;
$heavy : 800;
$black : 900;

* {
    font-family: $roboto;
}
