.page-filters {

    &__list {
        justify-content: flex-end;
        display: flex;
        align-items: center;


        &__option {

            display: none !important;

            &--visible {
                display: inline-flex !important;
            }
            gap: 20px;

            input {
                border: 1px solid $col-1;
                background: white;
                border-radius: 6px;
                padding: 0 15px;
            }
            button {
                @extend .button;
            }

            &--toggle {
                gap: 0 !important;
                border: 2px solid $col-1;
                span, a{
                    text-decoration: none;
                    color: black;
                    padding: 11px 25px;
                    display: block;
                    transform: scale(1) !important;
                    cursor: pointer;
                    &:first-of-type {
                        border-right: 1px solid black;
                    }

                    &.active {
                        background: $col-1;
                        color: white;
                    }
                }
            }

            &__labeled-item {
                display: flex;
                justify-content: center;
                flex-direction: column;
                label {
                    margin-bottom: 5px;
                }
                input {
                    &[type="text"], &[type="date"] {
                        height: 45px;
                    }
                }

                select {
                    height: 45px;
                }
            }
        }

        &--all-visible {
            .page-filters__list__option {
                display: inline-flex !important;
                &__option {

                }
            }
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
    }
}
