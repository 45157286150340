.pagination {
    list-style: none;
    li {
        display: inline-block;


        &:not(:last-of-type) {
            margin-right: 10px;
        }

        a {

            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            width: 36px;
            background: white;
            text-align: center;
            border: 1px solid #dee2e6;
            color: #8898aa;
            border-radius: 36px;
        }

        &.active {
            a {
                background: $col-1;
                color: white;
                box-shadow: 0 7px 14px rgba(50, 50, 93, .1);
            }
        }
    }

    &--async {
        li {
            display: inline-flex;
            text-decoration: none;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            height: 36px;
            width: 36px;
            background: white;
            text-align: center;
            border: 1px solid #dee2e6;
            color: #8898aa;
            border-radius: 36px;

            &.active {

                    background: $col-1;
                    color: white;
                    box-shadow: 0 7px 14px rgba(50, 50, 93, .1);

            }
        }
    }
}
