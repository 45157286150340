.ReactTable {
    border: 2px solid #eaeaea;
    font-size: 14px;
    .rt-expander {
        display: none !important;
    }
    &__options {
        background: $col-1;

        &__inner {
            ul {
                list-style: none;
                text-align: center;
                li {
                    display: inline-block;
                    line-height: 40px;
                    &:not(:last-of-type) {
                        margin-right: 45px;
                    }
                    span, a {
                        svg {
                            font-size: 18px;
                            top: 3px;
                            margin-right: 5px;
                        }
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: bold;
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    margin-top: 20px;

    .rt-table {

        .rt-thead {

            background: #b6ddee;

            font-weight: bold;
        }

        .rt-tbody {
            .rt-tr {
                background: #f5f8fa;
                &.-odd {
                    background: white;
                }

            }
        }

        .rt-tr {
            .rt-th, .rt-td {
                text-align: left;
                line-height: 30px;
                padding: 10px 0;
                border: 0;

                &:first-of-type {
                    padding-left: 20px;
                }
                &:last-of-type {
                    padding-right: 20px;
                }

                a, .link {
                    color: $col-1;
                    font-weight: bold;
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            &-group {
                border-bottom: 2px solid #eaeaea !important;
                &:last-of-type {
                    border-bottom: 0 !important;
                }
            }
        }
    }

    &.small-table {
        .rt-table {
            .rt-tr {
                .rt-th, .rt-td {
                    text-align: left;
                    line-height: 20px;
                    padding: 5px 0;

                    &:first-of-type {
                        padding-left: 20px;
                    }
                    &:last-of-type {
                        padding-right: 20px;
                    }
                }

            }
        }
    }
}
