.export-wizard {
    width: 500px;
    margin: 0 auto;
    padding: 25px;
    border: 2px solid $col-1;
    background: white;
    box-shadow: 0 0 10px #00000048;
    border-radius: 5px;
    &__type-select {
        label {
            display: block;
            margin-bottom: 5px;
        }

        select {
            height: 40px;
            width: 100%;
            background: white;
            -webkit-appearance: none;
            border: 1px solid $col-2;
            border-radius: 2px;
            padding: 0 20px;
            background-image: url('/assets/img/chevron-down-black.svg');
            background-position: calc(100% - 20px) 50%;
            background-repeat: no-repeat;
        }
    }

    &__type-fields {
        margin-top: 25px;
        &__list {
            &__option {
                display: none;

                &--visible {
                    display: block;
                }

                label {
                    display: block;
                    margin-bottom: 5px;
                    margin-top: 10px;
                }

                input {
                    &[type="date"] {
                        height: 40px;
                        width: 100%;
                        background: white;
                        border: 1px solid $col-2;
                        border-radius: 2px;
                        padding: 0 20px;
                    }
                }

                select {
                    height: 40px;
                    width: 100%;
                    background: white;
                    -webkit-appearance: none;
                    border: 1px solid $col-2;
                    border-radius: 2px;
                    padding: 0 20px;
                    background-image: url('/assets/img/chevron-down-black.svg');
                    background-position: calc(100% - 20px) 50%;
                    background-repeat: no-repeat;
                }
            }

            button {
                margin-top: 25px;
                @extend .button;
            }
        }
    }
}
