.confirm-popup {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
    width: 100%;
    height: 100%;

    &__inner {
        width: 100vw;
        max-width: 400px;
        height: 80vh;
        max-height: 200px;
        background: white;
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 25px;
            button {
                background: 0;
                border: 0;

            }
        }

        &__footer {
            max-height: calc(100% - 30px);
            overflow-y: auto;

            display: flex;
            justify-content: space-between;

            button {
                @extend .button;
                &.cancel {
                    border-color: $col-2;
                }
                &.confirm {
                    border-color: $col-1;
                }
            }
        }
    }

}
