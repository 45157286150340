.button {
    border: 1px solid black;
    padding: 5px;
    display: inline-block;
    text-decoration: none;
    color: black;
    cursor: pointer;
    -webkit-appearance: none;
    background: none;
    border: 2px solid #1d5fab;
    border-radius: 6px;
    padding: 10px 25px;
    font-size: 18px;
    font-family: $roboto;
    transform: scale(1);
    transition: all .3s;

    * {
        text-decoration: none;
        color: black;
    }

    &--small {
        padding: 5px 12px;
        font-size: 14px;
    }

    &[disabled], &--disabled {
        opacity: .5;
        pointer-events: none;
    }

    &:hover {
        transform: scale(.98);
        border-color: $col-1;
    }
}
