main {
    padding-top: $header-height;
    padding-bottom: $header-height;
    .main {
        &__inner {
            &__grid {
    
            }
        }
    }
}
