.log-list {
    list-style: none;

    display: grid;
    //grid-template-columns: 1fr 1fr;


    &__item {
        background: white;
        border-bottom: 1px solid black;
        padding: 10px 0;
        display: block;

        &__event {
            font-weight: bold;
            display: block;
        }

        &__user-date {
            margin-top: 10px;
            display: block;
            font-size: 14px;
        }
    }

    &--inline {
        li {
            background: transparent;
        }
    }
}
