.percentage-bar {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    background: grey;
    display: block;
    overflow: hidden;
    &__inner {
        display: block;
        background: $col-1;
        height: 100%;
        transition: width .3s;
        width: 0;
        background-size: 30px 30px;
        background-image: linear-gradient(135deg, rgba(255, 255, 255, .15) 25%,
                          transparent 25%,
                          transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%,
                          transparent 75%, transparent);
        animation: animate-stripes 3s linear infinite;

    }

    &__label {
        margin-bottom: 10px;
    }
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
