.page-header {
    padding-top: 25px;
    padding-bottom: 25px;
    h1 {
        margin: 0;
        font-size: 32px;
        color: $col-1;
        display: flex;
        align-items: flex-start
    }

    h3 {
        &.warning {
            font-size: 22px;
            color: red;
            display: flex;
            align-items: flex-end;
            margin-bottom: 30px;
        }
    }

    &--columns {
        display: flex;
        justify-content: space-between;
    }

    &__options {
        display: flex;
        align-items: center;


        li {
            @extend .button;
            display: inline-flex !important;
            gap: 20px;
            &:not(:last-of-type) {
                margin-right: 25px;
            }

            input {
                border: 1px solid $col-1;
                background: white;
                border-radius: 6px;
            }
            button {


                @extend .button;
                @extend .button--small;
                padding: 0px 12px !important;

            }
        }

        &__inner-toggle {
            padding: 0 !important;
            gap: 0 !important;
            overflow: hidden;

            span {
                padding: 11px 25px;
                display: block;

                &:first-of-type {
                    border-right: 1px solid black;
                }

                &.active {
                    background: $col-1;
                    color: white;
                }
            }

            &:hover {
                transform: scale(1) !important;

            }
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
    }
}
