.container {
    max-width: 100%;

    margin: 0 auto;

    padding: 0 20px;
    @include breakpoint($md) {
        padding: 0 70px;
    }

}
