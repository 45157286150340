.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    &__form-wrapper {
        box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 15%);

        background: white;
        padding: 25px;
        border-radius: 25px;

        input {
            box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .2);
            border: 0;
            background: white;
            width: 100%;
            height: 50px;
            width: 300px;
            display: block;
            margin-bottom: 50px;
            padding: 15px;
            border-radius: 10px;
        }

        button {

            box-shadow: 0 1px 3px rgba(50, 50, 93, .15), 0 1px 0 rgba(0, 0, 0, .2);
            border: 0;
            background: $col-1;
            width: 100%;
            height: 50px;
            width: 300px;
            display: block;
            color: white;
            margin-bottom: 0;
            padding: 15px;
            border-radius: 10px;
        }

        a, p {
            color: $col-1;
            text-decoration: none;
            margin-top: 25px;
            text-align: center;
            display: block;
        }
    }
}
