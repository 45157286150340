.stock-columns-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    list-style: none;

    label {
        display: block;
        width: 100%;
        order: 2;
    }

    li {
        display: grid;
        grid-template-columns: 20px 1fr;
    }
}
