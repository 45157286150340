.manage-stock {
    &__popup {
        &__current-stock, &__new-stock {
            border: 2px solid $col-1;
            padding: 25px;
            h3 {
                text-align: center;

            }
            ul {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                list-style: none;

                li {
                    text-align: center;
                    span {
                        display: block;

                        &:first-of-type {
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        &__new-stock {
            margin-top: 25px;
        }

        &__form {
            select {
                width: 100%;
            }
            &__type-select {
                margin: 25px 0;
            }

            &__fields {
                select {
                    margin-bottom: 25px;
                }
            }

            &__button {
                margin-top: 25px;
                span {
                    @extend .button;
                }
            }
        }
    }
}
