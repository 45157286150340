.log-form {
    width: 100%;
    display: block;
    margin-bottom: 25px;
    textarea {
        width: 100%;
        height: 100px;
        padding: 10px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;

        button {
            -webkit-appearance: none;
            background: white;
            @extend .button;

            &:first-of-type {
                border-color: $col-1;
            }
        }
    }

    &__add-button {
        display: block;
        width: 100%;
        margin-bottom: 25px;
        @extend .button;
    }
}
