.batchLabelsWithError {
    display: block;
    margin-top: 25px;

    &__list {
        list-style: none;
        margin-top: 25px;
        &__item {
            a {
                color: black;
            }
        }
    }

    &__button {
        @extend .button;
        margin-top: 25px;
    }
}
