.loader {
    &--fullheight {
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        background: rgba(0, 0, 0, .5);
        width: 100%;

        &:after {
            width: 50px;
            height: 50px;
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
            border: 2px dotted white;
            border-radius: 100%;
            content: '';
            animation: rotation 2s infinite linear;
        }
    }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
