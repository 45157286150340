@import '../../../../node_modules/breakpoint-sass/stylesheets/breakpoint';

@charset "UTF-8";

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/functions',
  'abstracts/mixins',
  'abstracts/animations';

// 2. Vendors
@import
  'vendors/normalize';

// 3. Base stuff
@import
    'base/typography',
    'base/reset';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer',
  'layout/main';

// 5. Components
@import
  'components/container',
  'components/login',
  'components/popup',
  'components/confirm-popup',
  'components/pagination',
  'components/export-wizard',
  'components/stock-columns-list',
  'components/form',
  'components/inline-editor',
  'components/log-list',
  'components/log-form',
  'components/full-page-loader',
  'components/page-header-filters',
  'components/page-filters',
  'components/percentage-bar',
  'components/batchLabelsWithError',
  'components/manage-stock',
  'components/datatable',
  'components/page_header',
  'components/page_content',
  'components/button';

// 6. Page-specific styles
@import
  'pages/home';

// 7. Themes
@import
  'themes/default';


  img {
      max-width: 100%;
  }

  body {
      background: #f7f7f7;
  }

  .auth-loader {
      background: $col-1;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 32px;
  }

  .reservations-list-batch {
      &:not(:last-of-type) {
          &:after {
              content: ', ';
          }
      }
  }

  .warehouse-list {
      display: block;
      list-style: none;
      &__item {
           display: block;
           list-style-type: none;
           &:not(:last-of-type) {
               margin-bottom: 15px;
           }
          span {
              padding-left: 15px;
              line-height: 1;
              font-size: 12px;
              display: block;
              &:before {
                  position: absolute;
                  left: 0;
                  content: '';
                  top: 0;
                  height: 100%;
                  width: 1px;
                  background: white;
              }
          }

          &__moving-date {
              padding-left: 0 !important;
              font-style: italic;
              &:before {
                  display: none;
              }
          }

      }

      &--large {
          .warehouse-list__item {
              span {
                  font-size: inherit;

                  &:before {

                      background: black;
                  }
              }
          }
      }
  }

  .spinner-small {
      display: block;
      width: 20px;
      height: 20px;
      border: 2px dashed black;
      border-radius: 20px;
      animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

  input.error {
      &:focus {
          outline: none;
      }
      border: 1px solid red;
  }

  .pdf-modal {
      position: fixed;
      width: 65%;
      min-width: 600px;
      height: 80vh;
      top: 100px;
      transform: translateX(-50%);
      left: 50%;
      border-radius: 5px;

      z-index: 9999;

      iframe {
          width: 100%;
          height: 100%;
      }

      &__button {
          position: absolute;
        top: -20px;
        left: -15px;
        width: 35px;
        height: 35px;
        background: #555;
        border: 0;
        box-shadow: 0 0 10px #00000048;
        border-radius: 50%;
        color: #fff;
        display: flex;
        align-items: center;
        z-index: 99999;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
      }
  }

  .column-actions {
      display: flex;
      gap: 10px;

      a, span {
          color: $col-1;
      }
  }

  .dropzone {
      border: 2px dotted $col-1;
      padding: 25px;


  }
  .ReactTable .rt-table .rt-tr .rt-td .whitespace-tooltip {
      white-space: nowrap !important;
      display: block;
      overflow: hidden;
          text-overflow: ellipsis;
  }

  .incoming-good-working {

      &:after {
          border-radius: 5px;
           background: rgba(0, 0, 0, .8);
          content: 'Aan het updaten..';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 28;
          color: white;
      }
  }
