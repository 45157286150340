.form {
    &__row {
        &:not(:last-of-type) {
            margin-bottom: 25px;
        }
        input, select, textarea {
            width: 100%;
            height: 40px;
            line-height: 40px;
            border: 2px solid $col-1;
            background: white;
            border-radius: 6px;
            padding-left: 10px;

            &[type="checkbox"] {
                width: auto;
                height: auto;
            }
        }
        button {
            @extend .button;
        }

        span {
            color: Red;
            font-size: 12px;
            display: block;
            margin-top: 8px;
        }

        &__files {
            p {
                color: red;
            }
        }

        &--table {
            table {
                margin-top: 15px;

                th {
                    text-align: left;



                }

                tr {
                    &.locked {
                        pointer-events: none;
                        opacity: .5;
                    }
                }

                td {
                    //width: 75px;
                    padding-right: 25px;
                    &.sku {
                        width: 250px;
                    }
                    &.supplier_code {
                        width: 150px;
                    }
                    &.delete {
                        width: 100px;
                        text-align: right;
                    }
                }
            }
        }
        &--multiple-list {
            ul {
                list-style: none;
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                li {
                    display: flex;
                    gap: 25px;
                    align-items: center;
                }
            }
        }
    }

    .current-file {
        p {
            color: black;
        }
        a {
            color: black;
        }

        &__item {
            margin-top: 5px;
            display: flex;
            align-items: center;
            gap: 20px;

            svg {
                color: red;
            }
        }
    }
}
