html{
	box-sizing: border-box;
	overflow-x: hidden;
}

*, *::after, *::before{
	box-sizing: inherit;
}

* {
	position: relative;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button {
	cursor: pointer;
	&:active, &:focus {
		outline: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}
