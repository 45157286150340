.header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    border-bottom: 5px solid $col-1;
    height: $header-height;
    background: white;
    .container {
        height: 100%;
    }
    &__inner {
        height: 100%;

        &__grid {
            height: 100%;
            display: grid;
            grid-template-columns: 100px 1fr 32px;
            grid-template-areas: "logo logo hamburger";


            @include breakpoint($md) {
                grid-template-columns: 160px 410px 1fr 200px;
                grid-template-areas: "logo menu menu account";
                grid-gap: 30px;
            }


            @include breakpoint($lg) {
                grid-gap: 60px;
                grid-template-areas: "logo menu menu account";
            }

            &__account {
                grid-area: account;
                display: none;
                @include breakpoint($lg) {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    &__inner {
                        gap: 15px;
                        display: flex;
                        position: relative;
                        width: auto;

                        svg {
                            top: -2px;
                        }
                    }

                }

                &__user-popup {
                    position: absolute;
                    top: 50px;
                    right: 0;
                    background: white;
                    padding: 25px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    border-radius: 15px;
                    box-shadow: 0 0 2px 2px rgba(0, 0, 0, .5);

                    &__dismiss {
                        display: flex;
                        justify-content: flex-end;
                    }
                    
                    select {
                        height: 40px;
                    }
                    button {
                        background: $col-1;
                        color: white;
                        -webkit-appearance: none;
                        border: 0;
                        padding: 10px;
                        font-size: 14px;
                    }
                }
            }

            &__hamburger {
                grid-area: hamburger;
                z-index: 99;
                display: flex;
                align-items: center;
                button {
                    width: 25px;
                    -webkit-appearance: none;
                    border: 0;
                    background: 0;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    justify-content: center;
                    height: 25px;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                    &:focus, &:active {
                        outline: 0;
                        background: 0;
                    }
                    span {
                        height: 3px;
                        width: 100%;
                        background: $col-1;
                        display: block;
                        left: 0;
                        transform: rotate(0);
                        transition: all .3s;
                        top: 0;
                        &:nth-of-type(1) {
                            transform-origin: left;
                        }
                        &:nth-of-type(3) {
                            transform-origin: right;
                        }
                    }

                    &.opened {
                        display: block;

                        span {
                            &:nth-of-type(1) {
                                transform: rotate(45deg);
                                top: -4px;
                            }
                            &:nth-of-type(2) {
                                left: 50px;
                            }
                            &:nth-of-type(3) {
                                top: -10px;
                                left: -7px;
                                transform: rotate(-45deg);
                            }
                        }
                    }
                }
                @include breakpoint($md) {
                    display: none;
                }
            }

            &__logo {
                grid-area: logo;
                &__wrapper {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    background: white;

                    img {
                        max-height: calc(#{$header-height} - 10px);

                    }
                }
            }


            &__navigation {
                display: none;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                background: white;
                grid-area: menu;
                &--opened {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                @include breakpoint($md) {
                    display: flex;
                    position: relative;
                    width: auto;
                    align-items: center;
                    justify-content: flex-start;
                }

                nav {
                    ul {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        li {
                            &:not(:last-of-type) {
                                margin-bottom: 50px;
                            }
                            @include breakpoint($xs) {
                                display: inline-block;
                                &:not(:last-of-type) {
                                    margin-right: 80px;
                                    margin-bottom: 0;
                                }
                            }

                            a {
                                color: black;
                                text-decoration: none;
                                font-family: $roboto;
                                font-size: 22px;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
